import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import EmptyImage from "../../images/empty-image1.png";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import { getServicesData } from "./servicesData";
import { NavLink } from "react-router-dom";

const SingleServiceContainer = tw.div`mt-6 flex flex-col-reverse md:flex md:flex-row md:px-6`;
const ServiceDetailsContainer = tw.div`mt-6 px-2 flex justify-center items-center relative`;
const Image = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
    height: 400px; // Adjust the height as needed
    // Adjust the height as needed
  `}
  ${tw`w-full bg-cover rounded-lg rounded-b-none md:rounded-b-lg md:rounded-l-none`} /* Remove border bottom */
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

const Info = styled.div`
  ${(props) => css`
    // Adjust the height as needed
  `}
  ${tw`p-8 border-2 rounded-lg pt-16 rounded-t-none md:rounded-t-lg md:rounded-r-none w-full`}
`;
const Details = styled.div`
  ${tw`mt-6 w-[85%]`}
`;
const Tagline = tw.div`uppercase text-black text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-black after:w-16 mt-2`;
const CreationDate = tw.div`uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`font-black text-3xl text-gray-900 mt-4`;
const SubHeading = tw.div`font-black text-2xl text-gray-900 mt-5`;
const Overview = tw.div`mt-5 text-gray-600 font-medium`;
const Description = tw.div`mt-2 text-gray-600 text-lg font-medium`;
const EndDescription = tw.div`mt-6 text-gray-600 text-lg font-medium`;
const List = tw.ul`mx-5 my-3 list-disc`;
const ListItem = tw.li`list-disc`;
const ListItemHeading = tw.strong`font-black text-lg text-gray-900 mt-5`;
const ListItemText = tw.p`inline-block`;
const ContactLink = tw.p`inline-block font-bold underline hover:text-black transition-all duration-300`;

export default function SingleService() {
  const { id } = useParams();
  const [service, setService] = useState({});

  useEffect(() => {
    const requiredService = getServicesData(id);
    setService({ ...requiredService });
  }, [id]);

  function toTitleCase(str) {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }

  return (
    <AnimationRevealPage>
      <Header />
      {service ? (
        <Container>
          <ContentWithPaddingXl>
            <SingleServiceContainer>
              <Info>
                <Title>
                  {service?.title ? toTitleCase(service?.title) : ""}
                </Title>
                <Tagline>{service?.tagline ? service.tagline : ""}</Tagline>
                <Overview>
                  {service?.overview ? service?.overview : ""}
                </Overview>
              </Info>
              <Image
                imageSrc={service?.imgSrc ? service?.imgSrc : EmptyImage}
              />
            </SingleServiceContainer>
            <ServiceDetailsContainer>
              <Details>
                {service?.intro ? (
                  <Description>
                    {service?.intro ? service?.intro : ""}
                  </Description>
                ) : null}
                {service?.howItWorks ? (
                  <>
                    <SubHeading>{"How Does This Work?"}</SubHeading>
                    <List>
                      {service.howItWorks.map((offer, ind) => (
                        <ListItem key={ind}>
                          <ListItemHeading>{offer.heading}: </ListItemHeading>
                          <ListItemText>{offer.content}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : null}
                {service?.packageOffer ? (
                  <>
                    <SubHeading>{"What This Package Offers:"}</SubHeading>
                    <List>
                      {service.packageOffer.map((offer, ind) => (
                        <ListItem key={ind}>
                          <ListItemHeading>{offer.heading}: </ListItemHeading>
                          <ListItemText>{offer.content}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : null}
                {service?.expectations ? (
                  <>
                    <SubHeading>{"What You Can Expect From Us:"}</SubHeading>
                    <List>
                      {service.expectations.map((offer, ind) => (
                        <ListItem key={ind}>
                          <ListItemHeading>{offer.heading}: </ListItemHeading>
                          <ListItemText>{offer.content}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : null}
                {service?.howTrainingWorks ? (
                  <>
                    <SubHeading>
                      {"How Will This Training Process be Working?"}
                    </SubHeading>
                    <List>
                      {service.howTrainingWorks.map((offer, ind) => (
                        <ListItem key={ind}>
                          <ListItemHeading>{offer.heading}: </ListItemHeading>
                          <ListItemText>{offer.content}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : null}

                {service?.endDescription ? (
                  <>
                    <EndDescription>{service?.endDescription}</EndDescription>
                  </>
                ) : null}
                <EndDescription>
                  {
                    "For getting in touch with us to avail this service from our team of experts, "
                  }
                  <NavLink to={"/contact-us"} state={id}>
                    <ContactLink>please drop us a message.</ContactLink>
                  </NavLink>
                </EndDescription>
              </Details>
              <DecoratorBlob1 />
              <DecoratorBlob2 />
            </ServiceDetailsContainer>
          </ContentWithPaddingXl>
        </Container>
      ) : null}
      <Footer />
    </AnimationRevealPage>
  );
}
