import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/app_sec_b.png";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import ApllicatioSecIcon from "../../images/app_sec_b.png";
import ManagedSOCIcon from "../../images/man_soc_b.png";
import RiskAssIcon from "../../images/risk_b.png";
import PenetrationIcon from "../../images/pen_b.png";
import PhishingIcon from "../../images/phishing_b.png";
import { useNavigate } from "react-router-dom";

// const Container = tw.div`relative`;

const Container = styled.div`
  ${tw`relative`}
  ${(props) =>
    props.id && `id: ${props.id};`} /* Set the id attribute if provided */
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12 cursor-pointer`}
  .imageContainer {
    ${tw`border-2 border-black text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-black`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const navigate = useNavigate();

  const cards = [
    {
      imageSrc: ApllicatioSecIcon,
      title: "Application Security",
      description:
        "From mobile apps and web services to desktop software, applications facilitate everything from communication and entertainment to business operations and financial transactions.",
    },
    {
      imageSrc: ManagedSOCIcon,
      title: "Managed SOC",
      description:
        "From sophisticated hackers to malware and insider threats, the risks are relentless and evolving. To combat these challenges effectively, businesses turn to Managed Security Operations Centers (SOCs).",
    },
    {
      imageSrc: RiskAssIcon,
      title: "Risk Assessment and Compliance",
      description:
        "Risk assessment is the process of identifying, analyzing, and evaluating potential risks that could affect an organization's objectives and operations.",
    },
    {
      imageSrc: PenetrationIcon,
      title: "Penetration Testing",
      description:
        "Penetration testing is a controlled and systematic process where our cybersecurity professionals simulate cyberattacks on an organization's infrastructure, applications, and devices.",
    },
    {
      imageSrc: PhishingIcon,
      title: "Security Awareness and Phishing Trainings",
      description:
        "In today's hyper-connected digital landscape, the threat of cyberattacks looms larger than ever. Security awareness and phishing training have emerged as indispensable tools in the battle against cyber threats.",
    },
  ];

  function toSnakeCase(inputString) {
    // Convert the string to lowercase and replace spaces with underscores
    return inputString.toLowerCase().replace(/\s+/g, "_");
  }

  return (
    <Container id={"services"}>
      <ThreeColumnContainer>
        <Heading>
          Our Professional <span tw="text-black">Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card
              onClick={() => navigate(`/services/${toSnakeCase(card.title)}`)}
            >
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
