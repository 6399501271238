export const servicesData = [
    {
        title: 'Application Security',
        tagline: 'Application SecurityShift your Security left, right, up and down',
        imgSrc: 'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
        overview: "In today's interconnected world, applications have become an integral part of our lives. From mobile apps and web services to desktop software, applications facilitate everything from communication and entertainment to business operations and financial transactions. However, the increasing reliance on applications also brings forth a significant concern: security.",
        intro: "Application security, often abbreviated as 'AppSec', is the practice of safeguarding software applications from vulnerabilities and cyber threats. In this service, we'll delve into the importance of application security and explore some essential practices to ensure the safety of your digital assets.",
        packageOffer: [
            { heading: 'Protecting Sensitive Data', content: 'Many applications handle sensitive user data, such as personal information, financial records, or medical records. Failure to secure this data can lead to privacy breaches, identity theft, and regulatory fines.', },
            { heading: 'Preventing Financial Loss', content: 'Cyberattacks can result in financial losses due to theft, fraud, or downtime. Application vulnerabilities can serve as entry points for attackers looking to exploit your systems.', },
            { heading: 'Maintaining Reputation', content: "A security breach can tarnish an organization's reputation and erode customer trust. Customers are more likely to engage with applications they trust.", },
            { heading: 'Compliance Requirements', content: 'Many industries have specific security regulations and compliance standards that organizations must adhere to. Neglecting application security can lead to non-compliance and legal consequences.', },
        ],
        expectations: [
            { heading: 'Application Security Testing', content: 'Implementing a comprehensive testing strategy that includes static analysis, dynamic analysis, and penetration testing. Identifying and addressing vulnerabilities during the development phase.', },
            { heading: 'Secure Coding Practices', content: 'Train developers in secure coding practices to write code that is less susceptible to vulnerabilities like SQL injection, cross-site scripting (XSS), and buffer overflows.', },
            { heading: 'Updates and Patch Management', content: "Keeping all software components, libraries, and frameworks up to date. Applying security patches promptly to addressing known vulnerabilities", },
            { heading: 'Web Application Firewall (WAF)', content: 'Deploying a WAF to filter and monitor incoming traffic to your web applications. It can help protect against common web application attacks.', },
            { heading: 'API Security', content: 'Securing APIs with proper authentication, authorization, and rate limiting. Using API keys, OAuth, or other relevant mechanisms to control access.', },
            { heading: 'Security Monitoring and Incident Response', content: 'Implementing real-time monitoring to detect unusual activities and security breaches. Developing an incident response plan to react swiftly to security incidents.', },
            { heading: 'Employee Training', content: 'Training your staff on security awareness. Employees should understand how to recognize and respond to potential security threats.', },
            { heading: 'Third-party Security Assessment', content: 'Assessing the security of third-party components, libraries, and services used within your application stack. Ensuring they meet security standards.', },
            { heading: 'Secure Development Lifecycle (SSDLC)', content: 'Integrating security into your development process from the outset. Conducting security reviews and threat modeling at each phase of the development lifecycle.', },
        ],
        endDescription: "In an era where applications play a pivotal role in our lives, application security is not an option; it's a necessity. Failing to prioritize application security can lead to dire consequences, including data breaches, financial losses, and damage to your reputation. By adopting best practices, staying informed about emerging threats, and fostering a culture of security within your organization, you can effectively protect your digital assets and ensure the longevity and trustworthiness of your applications in the ever-evolving digital landscape. Remember, a secure application is a reliable and resilient one."
    },
    {
        title: 'Managed SOC',
        tagline: 'Your Shield Against Cyber Threats',
        imgSrc: 'https://images.unsplash.com/photo-1568952433726-3896e3881c65?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
        overview: "In today's digital landscape, organizations face an ever-growing array of cyber threats. From sophisticated hackers to malware and insider threats, the risks are relentless and evolving. To combat these challenges effectively, businesses turn to Managed Security Operations Centers (SOCs).",
        intro: "We have a Security Operations Center (SOC), a centralized team or facility, responsible for monitoring and managing an organization's security posture. It's where our security analysts detect, analyze, respond to, and mitigate security incidents and vulnerabilities.",
        packageOffer: [
            { heading: '24/7 Vigilance', content: "Cyber threats don't adhere to a 9-to-5 schedule. Our Managed SOC provides round-the-clock monitoring, ensuring that security incidents are detected and addressed promptly, even during off-hours.", },
            { heading: 'Expertise and Experience', content: "Our Managed SOC providers employ skilled cybersecurity professionals who stay abreast of the latest threats and trends. They bring expertise that may be lacking in an organization's internal team.", },
            { heading: 'Cost-Effective', content: "Building and maintaining an in-house SOC can be prohibitively expensive. Therefore, we offer Managed SOCs, as organizations pay for the services they need, without the overhead of hiring and training a full-time team.", },
            { heading: 'Scalability', content: "As your organization grows, its security needs may change. Our Managed SOCs can scale their services to align with your evolving security requirements.", },
            { heading: 'Rapid Incident Response', content: "With dedicated security experts and automated tools, our Managed SOCs can respond quickly to incidents, minimizing potential damage and downtime.", },

        ],
        expectations: [
            { heading: 'Advanced Monitoring Tools', content: "With our Managed SOCs you can have state-of-the-art security information and event management (SIEM) systems and threat intelligence feeds to monitor network traffic and detect anomalies.", },
            { heading: 'Incident Detection and Response', content: "The SOC team that we will provide you can  investigate alerts generated by monitoring tools, determining if they indicate a genuine threat. In the event of an incident, they will respond promptly to contain and remediate the issue.", },
            { heading: 'Threat Intelligence', content: "Keeping abreast of emerging threats is crucial. We’ll provide you Managed SOCs to gather and analyze threat intelligence to proactively protect your organization against new vulnerabilities.", },
            { heading: 'Logging and Data Management', content: "Our team will collect and analyze logs and data from various sources, such as firewalls, antivirus systems, and intrusion detection systems, to identify security events.", },
            { heading: 'Security Policies and Compliance', content: "Our Managed SOCs will ensure that your organization adheres to security policies and compliance standards, helping you avoid legal and regulatory issues.", },
            { heading: 'Continuous Improvement', content: "They will assess and enhance security processes based on incident analysis and performance metrics.", },
        ],
        endDescription: "In an era where cyber threats are constant and ever-evolving, a Managed SOC is a strategic investment in the security and resilience of your organization. It provides the expertise, technology, and 24/7 vigilance needed to protect your digital assets from a wide range of threats. Whether you're a small business or a large enterprise, outsourcing your security operations to a Managed SOC can be the difference between succumbing to cyber threats and maintaining a robust defense against them. It's not just about safeguarding your data; it's about safeguarding your future."
    },
    {
        title: 'Risk Assessment and Compliance',
        tagline: 'Safeguarding Your Business in a Complex World',
        imgSrc: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3272&q=80',
        overview: "In today's interconnected and digital world, businesses face a multitude of risks that can disrupt operations, tarnish reputations, and lead to financial losses. To navigate these challenges successfully, organizations must prioritize risk assessment and compliance.",
        intro: "Risk assessment is the process of identifying, analyzing, and evaluating potential risks that could affect an organization's objectives and operations. It serves as the foundation for effective risk management and compliance efforts. Here's why risk assessment matters.",
        packageOffer: [
            { heading: 'Risk Identification', content: "It’ll help your organization when we’ll identify potential risks, both internal and external, that could impact your operations. This includes financial, operational, reputational, and cybersecurity risks.", },
            { heading: 'Prioritization', content: "By assessing risks, we’ll help you prioritize them based on their potential impact and likelihood. This will allow you to allocate the resources to address the most critical risks first.", },
            { heading: 'Mitigation and Preparedness', content: "A well-executed risk assessment by us will lead to the development of strategies and plans for risk mitigation and crisis response, ensuring that your organization is prepared to deal with unexpected events.", },
            { heading: 'Compliance Alignment', content: "Risk assessment assists in identifying areas where compliance with legal, regulatory, and industry-specific standards is essential. It forms the basis for aligning compliance efforts with identified risks.", },
        ],
        expectations: [
            { heading: 'Legal Obligations', content: "Laws and regulations are in place to ensure fair competition, protection of consumers, and maintaining the integrity of markets. Our Compliance team will help you to avoid legal consequences, fines, and penalties.", },
            { heading: 'Data Protection', content: "Regulations like GDPR (General Data Protection Regulation) and HIPAA (Health Insurance Portability and Accountability Act) require organizations to protect sensitive data. Our Compliance team will help prevent data breaches and ensure data privacy.", },
            { heading: 'Reputation Management', content: "Non-compliance can damage an organization's reputation. Customers and partners are more likely to trust businesses that adhere to ethical and legal standards. Our team will help you increase your client trafficking by providing you compliance support.", },
            { heading: 'Risk Reduction', content: "Compliance efforts often align with risk mitigation. For example, cybersecurity regulations mandate certain security measures that will help you in protecting against data breaches and cyberattacks.", },
            { heading: 'Risk-Informed Compliance', content: "Risk assessments done by our team will help identify areas of vulnerability and potential threats. Compliance efforts can then focus on addressing these specific risks, ensuring that resources are used effectively.", },
            { heading: 'Continuous Improvement', content: "Both risk assessment and compliance should be ongoing processes. Therefore, our team will perform regular risk assessments to uncover new threats, and compliance efforts must adapt to changing regulations and business environments.", },
        ],
        endDescription: "In today's business landscape, risk assessment and compliance are not optional. They are critical components of a proactive approach to safeguarding your business against threats, legal issues, and reputational damage. By conducting thorough risk assessments, aligning compliance efforts with identified risks, and continuously improving both processes via our team, your organization can navigate complex regulatory landscapes and emerging risks while ensuring the longevity and resilience of your operations. In essence, risk assessment and compliance are key pillars of responsible and sustainable business practices in the 21st century."
    },
    {
        title: 'Penetration Testing',
        tagline: 'Unveiling Vulnerabilities to Strengthen Your Cybersecurity',
        imgSrc: 'https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
        overview: "In an age where cyber threats loom large, businesses must be proactive in protecting their digital assets. Penetration testing, often referred to as pen testing or ethical hacking, is a critical cybersecurity practice that helps organizations identify vulnerabilities and weaknesses in their IT systems, networks, and applications before malicious hackers can exploit them.",
        intro: "How does Penetration Testing work? Penetration testing is a controlled and systematic process where our cybersecurity professionals simulate cyberattacks on an organization's infrastructure, applications, and devices. Their goal is to uncover vulnerabilities, assess the security posture, and provide actionable recommendations to mitigate risks effectively.",
        howItWorks: [
            { heading: 'Black Box Testing', content: "Our testers will require no prior knowledge of your system. They’ll mimic a scenario where an external attacker with limited information attempts to breach your system.", },
            { heading: 'White Box Testing', content: "Our testers will have full knowledge of your system, including architecture, source code, and configurations. This approach will allow us for a comprehensive assessment.", },
            { heading: 'Gray Box Testing', content: "Our testers will have partial knowledge of your system. This approach will simulate an attack scenario where an insider or a compromised user has limited access.", },
            { heading: 'Social Engineering Testing', content: "This will involve manipulating human psychology to gain access to systems or sensitive information. Our testers may use phishing, pretexting, or other social engineering tactics.", },

        ],
        packageOffer: [
            { heading: 'Planning and Reconnaissance', content: "Defining the scope, goals, and objectives of the test. Gathering information about the target systems and potential vulnerabilities.", },
            { heading: 'Scanning and Enumeration', content: "Conducting scans to identify open ports, services, and vulnerabilities. Enumerating system details and potential attack vectors.", },
            { heading: 'Exploitation', content: "Attempts to exploit identified vulnerabilities to gain unauthorized access or control over the target systems.", },
            { heading: 'Post-Exploitation', content: "Assessing the extent of control gained, escalating privileges, and pivoting to access other systems. This step simulates the actions of a real attacker.", },
            { heading: 'Analysis and Reporting', content: "Documenting our findings, including vulnerabilities, risks, and recommended remediation steps. Providing a comprehensive report to stakeholders.", },
            { heading: 'Remediation', content: "Working with your organization to address and fix the identified vulnerabilities and weaknesses.", },
            { heading: 'Re-testing', content: "Verifying that vulnerabilities have been successfully remediated through follow-up testing.", },

        ],
        expectations: [
            { heading: 'Vulnerability Discovery', content: "Penetration testing done by our team will reveal potential vulnerabilities that might not be apparent through routine security assessments. These vulnerabilities can include software flaws, misconfigurations, or outdated systems.", },
            { heading: 'Risk Assessment', content: "By identifying vulnerabilities,we will help your organization assess the level of risk accurately. This will allow you to prioritize and allocate resources for remediation effectively.", },
            { heading: 'Mimicking Real-World Attacks', content: "Our Penetration testing team will simulate real-world attack scenarios, providing insight into how attackers might exploit vulnerabilities. This will help your organization understand your exposure to various threats.", },
            { heading: 'Compliance and Regulatory Requirements', content: "Many industries and regulations mandate regular penetration testing as part of cybersecurity compliance. Meeting these requirements is essential to avoid penalties and maintain trust with customers. Hence, we’d love to work on that for you.", },
            { heading: 'Security Improvement', content: "After we identify vulnerabilities, your organization can take proactive steps to strengthen your security posture. This includes patching vulnerabilities, updating configurations, and enhancing security policies and procedures.", },
        ],
        endDescription: "Penetration testing is a proactive and indispensable component of modern cybersecurity. It empowers organizations to identify and rectify vulnerabilities, reduce the risk of cyberattacks, and maintain regulatory compliance. By conducting regular penetration tests and staying one step ahead of potential threats, businesses can fortify their cybersecurity defenses and protect their sensitive data, reputation, and financial assets in an increasingly hostile digital landscape. Remember, in the world of cybersecurity, knowledge is power, and penetration testing is a powerful tool to wield that knowledge in your favor."
    },
    {
        title: 'Security Awareness and Phishing Trainings',
        tagline: 'Empowering Your Defense',
        imgSrc: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3270&q=80',
        overview: "In today's hyper-connected digital landscape, the threat of cyberattacks looms larger than ever. Security awareness and phishing training have emerged as indispensable tools in the battle against cyber threats.",
        packageOffer: [
            { heading: 'Phishing Prevalence', content: ": Phishing attacks, where malicious actors trick individuals into divulging sensitive information, continue to proliferate. We will help train individuals from your organization to recognize and respond to phishing attempts as essential to thwart these threats.", },
            { heading: 'Comprehensive Defense', content: "Cybersecurity is a collective effort, and every individual within an organization plays a part. Therefore, we offer security awareness and phishing training to empower employees to become active participants in the organization's security posture.", },

        ],
        expectations: [
            { heading: 'Phishing Simulations', content: "We will help your employees get enlightened with this phenomenon by sending mock phishing emails or messages to test their ability to identify phishing attempts. Feedback will also be provided to individuals who fall for these simulations, allowing them to learn from their mistakes.", },
            { heading: 'Interactive Training Modules', content: "We will be engaging online modules covering various cybersecurity topics, such as password security, safe browsing, and recognizing phishing attempts. These modules will offer practical knowledge and quizzes to reinforce learning.", },
            { heading: 'Simulated Cybersecurity Incidents', content: "We will be providing realistic simulations of cybersecurity incidents, such as data breaches or ransomware attacks to help employees understand the potential consequences of security lapses.", },
            { heading: 'Policy Awareness', content: "Ensuring that employees are aware of and understand the organization's cybersecurity policies and procedures we can offer interactive seminars. This will include data handling, password policies, and incident reporting.", },
            { heading: 'Regular Updates', content: "Cyber threats evolve rapidly, so training materials should be regularly updated to reflect current threats and best practices by us for your organization.", },
            { heading: 'Phishing Reporting', content: "Encouraging employees to report suspicious emails or messages promptly. Establishing clear reporting mechanisms and a no-blame culture to promote reporting.", },
        ],
        howTrainingWorks: [
            { heading: 'Assessment', content: "Beginning with an assessment of employees' current cybersecurity knowledge and awareness levels. This helps tailor training to address specific gaps.", },
            { heading: 'Training Delivery', content: "Delivering training modules and simulations through a combination of online courses, workshops, and interactive sessions.", },
            { heading: 'Testing and Evaluation', content: "Periodically testing employees' knowledge and awareness through quizzes, simulations, and real-world scenarios.", },
            { heading: 'Feedback and Improvement', content: "Providing constructive feedback to individuals who need improvement. Continuously evaluating and refining the training program based on results and feedback.", },
            { heading: 'Regular Reinforcement', content: "Cybersecurity training should be an ongoing process. For this we will regularly revisit and update training materials to stay current with emerging threats.", },

        ],
        endDescription: "Security awareness and phishing training are not merely checkboxes in a cybersecurity checklist; they are fundamental pillars of a strong cyber defense strategy. By equipping employees with the knowledge and skills to recognize and respond to security threats, organizations can significantly reduce their vulnerability to cyberattacks. Remember, in the world of cybersecurity, awareness is the first line of defense. When every employee becomes a vigilant guardian of your organization's digital assets, you build a robust security culture that strengthens your defenses against the ever-present threat of cyberattacks."
    },
]

function toSnakeCase(inputString) {
    // Convert the string to lowercase and replace spaces with underscores
    return inputString.toLowerCase().replace(/\s+/g, '_');
}

export const getServicesData = (id) => {
    const requiredService = servicesData.find(service => toSnakeCase(service.title) === id)
    return requiredService
}

