import React, { useState, useRef, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const Container = tw.div`relative`;
const CaptchaContainer = tw.div`mt-6`;
const TwoColumn = tw.div`flex flex-col-reverse md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-5/12 flex-shrink-0 h-80 md:h-auto mt-8 md:mt-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-black`;
const Select = tw.select`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-black`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-black">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);
  const location = useLocation();

  function toTitleCase(inputString) {
    // Split the string into words using spaces or underscores as separators
    const words = inputString.split(/[\s_]+/);

    // Capitalize the first letter of each word and convert the rest of the word to lowercase
    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    // Join the words back into a string with spaces between them
    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  }

  useEffect(() => {
    debugger;
    const serviceID = location.state;
    if (serviceID) {
      const getService = toTitleCase(serviceID);
      setService(getService);
    } else {
      setService("");
      window.history.replaceState("", document.title);
    }
  }, []);

  const handleCaptchaChange = (value) => {
    // Handle reCAPTCHA verification status
    setCaptchaVerified(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCaptchaVerified) {
      const mailtoLink = `mailto:info@securpio.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(
        `Hi, \n\n${message}\n\n\nName: ${name}\nEmail: ${email}\nService: ${service}`
      )}`;

      window.open(mailtoLink);

      setEmail("");
      setName("");
      setSubject("");
      setService("");
      setMessage("");
      setCaptchaVerified(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset(); // Reset the ReCAPTCHA component
      }
    } else {
      toast.error("Please verify the captcha first!");
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <Select
                name="service"
                value={service}
                required
                onChange={(e) => setService(e.target.value)}
              >
                <option disabled value="">
                  Services
                </option>
                <option value="Application Security">
                  Application Security
                </option>
                <option value="Managed Soc">Managed SOC</option>
                <option value="Risk Assessment And Compliance">
                  Risk Assessment And Compliance
                </option>
                <option value="Penetration Testing">Penetration Testing</option>
                <option value="Security Awareness And Phishing Trainings">
                  Security Awareness And Phishing Trainings
                </option>
              </Select>
              <Textarea
                name="message"
                placeholder="Your Message Here"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <CaptchaContainer>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LfC0H8oAAAAAPr2I1utF4rjQQtnOL_JhziRrzRM"
                  onChange={handleCaptchaChange}
                />
              </CaptchaContainer>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
