import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactUsPage from "pages/ContactUs.js";
import BlogIndexPage from "pages/BlogIndex.js";
import SingleBlogPost1 from "components/blogs/SingleBlogPost1";
import SingleService from "components/features/SingleService";
import Services from "components/features/Services";

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {

  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/components/:type/:subtype/:name"
            element={<ComponentRenderer />}
          />
          <Route
            path="/components/:type/:name"
            element={<ComponentRenderer />}
          />
          {/* <Route path="/thank-you" element={<ThankYouPage />} /> */}
          <Route path="/" element={<MainLandingPage />} />
          <Route path="/blogs" element={<BlogIndexPage />} />
          <Route path="/blogs/:id" element={<SingleBlogPost1 />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<SingleService />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
        </Routes>
      </Router>
    </>
  );
}
