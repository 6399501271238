import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { getServicesData } from "./servicesData";
import Features from "./DashedBorderSixFeatures";

const SingleServiceContainer = tw.div`mt-6 flex flex-col-reverse md:flex md:flex-row md:px-6`;
const Image = styled.div`
  ${(props) => css`
    background-image: url("${props.imageSrc}");
    height: 400px;
  `}
  ${tw`w-full bg-cover rounded-lg rounded-b-none md:rounded-b-lg md:rounded-l-none`} /* Remove border bottom */
`;

const Info = styled.div`
  ${tw`p-8 border-2 rounded-lg pt-16 rounded-t-none md:rounded-t-lg md:rounded-r-none w-full`}
`;
const Tagline = tw.div`uppercase text-black text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-black after:w-16 mt-2`;
const Title = tw.div`font-black text-3xl text-gray-900 mt-4`;
const Overview = tw.div`mt-5 text-gray-600 font-medium`;

export default function Services() {
  const { id } = useParams();
  const [service, setService] = useState({});

  useEffect(() => {
    const requiredService = getServicesData(id);
    setService({ ...requiredService });
  }, [id]);

  return (
    <AnimationRevealPage>
      <Header />
      {service ? (
        <Container>
          <ContentWithPaddingXl>
            <SingleServiceContainer>
              <Info>
                <Title>{"What We Offer"}</Title>
                <Tagline>{"Security at Affordability"}</Tagline>
                <Overview>
                  {
                    "At Securpio, we understand the critical importance of robust cybersecurity in today's digital landscape and go beyond conventional cybersecurity, offering personalized solutions that align with your unique business requirements. Our comprehensive suite of services is tailored to fortify your organization against a myriad of cyber threats, ensuring your digital assets are secure and your operations run smoothly."
                  }
                </Overview>
              </Info>
              <Image
                imageSrc={
                  "https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </SingleServiceContainer>
            <Features />
          </ContentWithPaddingXl>
        </Container>
      ) : null}
      <Footer />
    </AnimationRevealPage>
  );
}
